@import "globals";

.sa-visualizer__toolbar {
}

.sa-visualizer__content {
  font-family: SegoeUI, Arial, sans-serif;
  display: inline-block;
  overflow: hidden;
  width: 100%;
}

.sa-visualizer__footer {
  margin-top: 10px;
}

.sa-visualizer__footer-title {
  display: inline-block;
  font-family: Raleway;
  font-size: 14px;
  color: #404040;
  line-height: 34px;
  vertical-align: middle;
  margin: 0;
}

.sa-visualizer__footer-content {
  margin-top: 10px;
}
