$main-color: #1ab394;
$auxiliary-color: #ff9814;
$auxiliary-color-1: #666666;
$auxiliary-color-2: #1948b3;
$danger-color: #d9534f;
$disabled-color: #b0b0b0;

$blue-color: #394cff;
$purple-color: #aa42d0;
$green-color: #41c02c;

$text-color-inverse: #fff;
$text-color: #404040;

$content-max-width: 1170px;

$background-color: #f7f7f7;
$background-color-dark: #eeeeee;
$background-disabled-color: #b7b7b7;

$border-color: #e7e7e7;

$form-element-height: 34px;

$svg-color: rgba(#9f9f9f, 0.4);
$svg-hover-color: #9f9f9f;
